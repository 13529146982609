import { captureException } from '@sentry/minimal';

export const sendLog = (payload: any): void => {
  // @ts-ignore
  return fetch(process.env.REACT_APP_STACK_DRIVER_LOGGING_URL, {
    body: JSON.stringify(payload),
    method: 'POST',
  }).catch((err) => {
    captureException(err);
  });
};
