import React from 'react';
import { Button } from '@scriptaddicts/yamm-ui-components';
import PurchasePlanCard from './PurchasePlanCard';
import {
  StyledCompleteFeatureListDiv,
  StyledContainer,
  UpgradeTitle,
  StyledIntervalContainer,
  SectionLoading,
} from './styles';
import { getBillingPlan } from './data/billingPlans';
import { useConnect } from './connect';

export type PurchasePlansProps = ReturnType<typeof useConnect>;
const PurchasePlans = ({
  plan,
  navigate,
  isLoadingSubscription,
  prices,
  isGmail,
  restrictions,
  interval,
  legacyPlanType,
}: PurchasePlansProps) => {
  if (isLoadingSubscription) {
    return null;
  }

  return (
    <>
      <StyledIntervalContainer>
        <UpgradeTitle>Upgrade</UpgradeTitle>

        <StyledCompleteFeatureListDiv>
          <a
            rel="noopener"
            /* eslint-disable-next-line react/jsx-no-target-blank */
            target="_blank"
            href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/pricing`}
            style={{ textDecoration: 'none' }}
          >
            <Button
              aria-label="See complete feature list"
              variant="gray"
              size="small"
            >
              See complete feature list
            </Button>
          </a>
        </StyledCompleteFeatureListDiv>
      </StyledIntervalContainer>
      {prices ? (
        <StyledContainer $onlyOne={restrictions.isPremiumPlanRestricted}>
          <PurchasePlanCard
            isLegacy={!!legacyPlanType}
            isCurrentPlan={plan === 'PRO'}
            isDowngrade={plan === 'PREMIUM'}
            interval={interval}
            onClick={navigate}
            price={isGmail ? prices.gmail.pro : prices.workspace.pro}
            isGmailUser={isGmail}
            {...getBillingPlan('pro', restrictions)}
          />
          {!restrictions.isPremiumPlanRestricted && (
            <PurchasePlanCard
              isLegacy={!!legacyPlanType}
              isDowngrade={false}
              isCurrentPlan={plan === 'PREMIUM'}
              interval={interval}
              onClick={navigate}
              price={prices.workspace.premium}
              gmailPrice={prices.gmail.premium}
              isGmailUser={isGmail}
              {...getBillingPlan('premium', restrictions)}
            />
          )}
        </StyledContainer>
      ) : (
        <SectionLoading />
      )}
    </>
  );
};

export default PurchasePlans;
