import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from 'containers/App';
import * as serviceWorker from './serviceWorker';
import { getEnvironmentLowerCased } from './utils/indexUtils';
import { sendLog } from './utils/logToStackdriver';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: getEnvironmentLowerCased(),
  release: `yamm-webapp@v${process.env.REACT_APP_VERSION}`,
  ignoreErrors: ['Non-Error promise rejection captured'],
});

// when this code is executed it means we are running in a browser supporting the webapp
// @ts-ignore
YammReactAppStatus.setStatus('operative');

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

sendLog({ app_version: `${process.env.REACT_APP_VERSION}` });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
